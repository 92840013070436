.product-wp {
  width: 915px;
  margin: 0 auto;
  position: relative;
  margin-top: 30px;

  .product-item {
    display: block;
    width: 285px;
    height: 154px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 6px 7px 20px 0px rgba(98, 137, 183, 0.1);
    text-align: center;
    float: left;
    margin: 30px 30px 0 0;

    .icon {
      margin-top: 24px;
      font-size: 36px;
      line-height: 52px;
    }

    .name {
      font-size: 16px;
      color: #151734;
      line-height: 20px;
    }

    .desc {
      color: #a7b4c9;
      line-height: 20px;
      margin-top: 5px;
    }
  }

  .product-item:nth-child(3n) {
    margin-right: 0;
  }
}

.product-wp::after {
  content: '';
  display: block;
  line-height: 0;
  clear: both;
}
