.rt-scroll {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  flex: 1;

  .rt-scroll-inner {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: -17px;
    padding-right: 44px;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .rt-scroll-bar {
    position: absolute;
    right: 4px;
    width: 5px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.2);
    transition: width 0.2s, background-color 0.2s;
    cursor: pointer;

    &:hover {
      width: 8px;
      background-color: rgba(255, 255, 255, 0.3);
    }

    &.active {
      width: 8px;
      background-color: rgba(255, 255, 255, 0.3);
    }
  }

  .rt-shadow-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0px;
    box-shadow: 0px 0px 15px 14px rgba(34, 34, 34, 1);
  }
}
