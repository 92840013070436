@import '../common/common';

.rt-modal,
.rt-modal-mask {
  &.show {
    animation: show 0.3s;
    animation-fill-mode: forwards;
  }

  &.hide {
    animation: hide 0.3s;
    animation-fill-mode: forwards;
  }

  &.init,
  &.none {
    opacity: 0;
    display: none;
  }
}

.rt-modal-mask {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 0;

  width: 100vw;
  height: 100vh;

  background: rgba(22, 22, 22, 0.8);
}

.rt-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);

  width: 400px;
  border-radius: 4px;

  background: #262626;

  .rt-modal-header {
    height: 34px;
    padding: 0 16px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-size: 14px;
    color: rgba(255, 255, 255, 0.47);
    background: rgba(255, 255, 255, 0.02);

    .rt-modal-close {
      width: 14px;
      height: 14px;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      cursor: pointer;

      i {
        transition: color 0.3s;
      }

      &:hover {
        i {
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }

  .rt-modal-content {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.86);
  }

  .rt-modal-footer {
    margin-bottom: 30px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .rt-modal-button {
      width: 128px;
    }
  }
}
