@import './common/common';

.rich-text {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);

  .rt-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .line {
      width: 1px;
      height: 30px;
      background: rgba(255, 255, 255, 0.08);
    }

    .speed-box {
      position: relative;

      .speed {
        display: none;
        width: 48px;
      }

      .text {
        display: inline-block;
        width: 48px;
      }

      &:hover {
        .speed {
          display: inline-block;
        }

        .text {
          display: none;
        }
      }
    }
  }

  .rt-content {
    min-height: 350px;
    flex: 1;
    margin-top: 10px;
    padding: 20px 0;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    background: rgba(255, 255, 255, 0.05);
  }

  .rt-context {
    position: relative;
    margin-left: 30px;
    min-height: 100%;
    line-height: 28px;

    .rt-placeholder {
      display: inline-block;
      height: 28px;
      line-height: 28px;
      color: rgba(255, 255, 255, 0.3);
      animation: show 0.3s;

      &::selection {
        background: none;
      }
    }

    .rt-text,
    .rt-polyphonic,
    .re-polyphonic-text,
    .rt-number,
    .rt-number-text,
    .rt-wrap {
      &::selection {
        background: rgba(80, 129, 255, 0.5);
      }
    }

    .rt-pause-inner,
    .rt-pause-invalid-inner,
    .rt-tag {
      user-select: none;

      &::selection {
        background: none;
      }
    }

    .rt-text {
      display: inline-block;
      height: 28px;
      line-height: 28px;
      font-weight: normal;

      font-family: Microsoft YaHei;
      font-size: 14px;
      letter-spacing: 2px;
      color: rgba(255, 255, 255, 0.87);
      cursor: text;
      transition: color 0.3s;

      &.active {
        color: #ff8e6d;
      }
    }

    .rt-pause {
      display: inline-block;
      height: 18px;

      cursor: text;

      .rt-pause-inner {
        position: relative;
        top: -1px;
        height: 18px;
        margin: 0 3px;
        padding: 0 5px;

        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        background: #2f81d3;
        border-radius: 2px;
        cursor: pointer;

        font-family: PingFang SC;
        font-size: 12px;
        letter-spacing: 2px;
        color: #fff;
      }
    }

    .rt-pause-invalid {
      display: inline-block;
      position: relative;
      bottom: -1px;
      height: 18px;

      cursor: text;

      .rt-pause-invalid-inner {
        height: 18px;
        line-height: 28px;
        margin: 0 3px;
        padding: 0 7px;
        border-radius: 2px;

        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        background: rgba(255, 114, 115, 0.5);
        font-family: FZLanTingHei-R-GBK;
        font-size: 12px;
        letter-spacing: 2px;
        color: #fff;
        cursor: pointer;
      }

      .rt-pause-icon {
        margin-right: 4px;
      }
    }

    .rt-polyphonic,
    .rt-number {
      height: 28px;
      color: #00e7cb;
      position: relative;
      display: inline;
      white-space: nowrap;

      .re-polyphonic-text,
      .rt-number-text {
        font-size: 14px;
        letter-spacing: 2px;
        cursor: pointer;

        &.active {
          color: #ff8e6d;
        }
      }

      .rt-tag {
        position: relative;
        top: -1px;
        height: 18px;
        line-height: 28px;
        margin: 0 3px;
        padding: 0 4px;

        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #00806f;
        border-radius: 2px;
        color: #fff;
        font-size: 12px;
        cursor: pointer;
      }
    }

    .rt-wrap {
      display: block;
      height: 28px;
      line-height: 28px;
      //content:' ';
    }

    .rt-input {
      position: relative;
      left: -1px;
      display: inline-block;
      width: 0;

      font-weight: normal;

      // 启动输入法时，宽度可自动拉伸
      &.composition {
        display: inline-block;
        width: auto;

        .input {
          position: relative;
        }
      }

      .input {
        display: inline-block;
        min-width: 1px;
        width: auto;
        height: 20px;
        line-height: 20px;
        padding: 0;
        border: 0;
        font-size: 14px;
        text-align: justify;
        caret-color: #fff;
        color: rgba(255, 255, 255, 0.87);

        &:focus {
          outline: none;
        }
      }
    }
  }

  .rt-setting-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 30px 0;

    .rt-setting-label {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.47);
    }

    .rt-word-count {
      font-family: FZLanTingHei-R-GBK;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.25);

      .tr-count {
        color: rgba(255, 255, 255, 0.47);
      }
    }
  }

  .text-box,
  .rt-import-box {
    margin: 35px 0 37px;

    .text-center {
      text-align: center;
      line-height: 22px;
    }

    .text-orange {
      color: rgb(219, 50, 50);
    }

    .text-white {
      color: #fff;
      margin-top: 14px;
    }

    .text-gray {
      font-size: 12px;
      margin-top: 4px;
      color: rgba(255, 255, 255, 0.47);
    }
  }

  .rt-import-box {
    margin: 30px 26px;

    .rt-import-item {
      width: 164px;
      height: 106px;
      border-radius: 4px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      background: rgba(255, 255, 255, 0.02);
      cursor: pointer;
      transition: background 0.3s;

      &:hover {
        background: rgba(255, 255, 255, 0.08);
      }
    }
  }
}
