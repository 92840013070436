.rt-process {
  position: relative;

  .progress-round {
    stroke-width: 1.5px;
    transition: stroke-dasharray 0.3s, fill 0.3s;
  }

  .process-circle {
    stroke: rgba(255, 255, 255, 0.4);
    stroke-width: 1.5px;
  }

  .center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
  }
}
