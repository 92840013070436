@import '~antd/dist/antd.less';

@import '~antd/lib/style/themes/default.less';

.text-primary {
  color: @primary-color;
}

.text-danger {
  color: @red-6;
}

.text-success {
  color: @green-6;
}

.text-warning {
  color: @warning-color;
}

.text-error {
  color: @red-6;
}

@primary-color: #1e88e5;