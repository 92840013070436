.rt-space {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .rt-space-item {
    display: inline-block;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}
