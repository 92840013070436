@import './common/page.scss';
@import './common/antd-reset.scss';

html {
  font-size: 14px;
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
}

button {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

// .flex-row {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
// }

.text-delete {
  text-decoration: line-through;
}

.text-bord {
  font-weight: bold;
}

.fontsize-12 {
  font-size: 12px !important;
}

.modal-confirm-with-close {
  .close-icon {
    position: absolute;
    right: 4px;
    top: 4px;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #999;
  }
}

.modal-tip {
  font-size: 12px;
  color: #f5222d; // TODO
  margin: 5px 0 3px 0;
}