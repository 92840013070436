@import '../common/common';

$show-time: 0.3s;

.rt-audition {
  position: fixed;
  height: 30px;
  border-radius: 15px;

  color: #fff;
  background-color: #ff5e2f;
  transition: width $show-time linear, transform $show-time linear;
  transform: translateX(-50%);

  &.standby,
  &.loading {
    width: 88px;
  }

  &.playing,
  &.pause {
    width: 159px;
  }

  .rt-audition-button {
    width: 88px;
    height: 30px;

    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    font-size: 14px;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.3s;
    animation: show $show-time;
    overflow: hidden;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    &.play,
    &.loading,
    &.playing {
      border-radius: 15px;
    }

    &.continue,
    &.pause {
      width: 79px;
      border-radius: 15px 0 0 15px;
    }

    &.stop {
      width: 79px;
      border-radius: 0 15px 15px 0;
      opacity: 0;
      animation: show $show-time ease $show-time;
      animation-fill-mode: forwards;
    }

    .rt-audition-loading {
      margin: 0 12px 0 6px;
    }

    .rt-audition-process {
      margin: 0 12px 0 6px;

      &.stop {
        margin-left: 9px;
      }
    }
  }

  .rt-line {
    display: inline-block;
    width: 1px;
    height: 15px;
    background: rgba(255, 255, 255, 0.1);
  }

  .rt-arrow {
    width: 16px;
    height: 4px;

    position: absolute;
    bottom: -3.8px;
    left: 50%;
    overflow: hidden;
    transform: translateX(-50%);
  }
}
