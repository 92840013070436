.ant-input-disabled,
.ant-input[disabled],
.ant-select-disabled,
.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-radio-disabled + span,
.ant-textarea-disabled {
  color: #666;
}

.ant-table {
  td {
    word-break: break-all;
  }

  .no-padding-row + .ant-table-expanded-row td {
    padding: 0 !important;
  }
}

.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-left
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table
  > .ant-table-thead
  > tr
  > th,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-right
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table
  > .ant-table-thead
  > tr
  > th,
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-left
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table
  > .ant-table-tbody
  > tr
  > td,
.ant-table-small
  > .ant-table-content
  > .ant-table-fixed-right
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table
  > .ant-table-tbody
  > tr
  > td {
  padding: 8px 4px;
}

.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  background-color: #aaa;
}

.ant-modal-wrap .ant-select-multiple .ant-select-selector {
  width: 100%;
}

.flex-form-item {
  .ant-form-item {
    display: flex;

    label {
      width: 110px;
      display: inline-block;
      text-align: left;
    }

    .ant-input,
    .ant-select {
      width: 280px;
    }
  }
}

.flex-form-item-dialog {
  width: 460px !important;
}

.upload-btn-wp {
  display: inline-block;

  .ant-upload-list {
    display: none;
  }
}

.no-upload-list-wp {
  .ant-upload-list {
    display: none;
  }
}

.font-size-12 {
  font-size: 12px;
}
