@import '../common/common';

.rt-options-bar {
  position: fixed;
  height: 36px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  border-radius: 4px;
  background: #525252;

  &.green {
    .rt-options-item {
      &.active {
        background: #00806f;
        border: 0;

        &:hover {
          background: #01b49c;
        }
      }
    }
  }

  &.blue {
    .rt-options-item {
      &.active {
        background: #2f81d3;
        border: 0;

        &:hover {
          background: #46a2ff;
        }
      }
    }
  }

  &.show {
    animation: show 0.3s;
  }

  &.hide {
    animation: hide 0.3s;
  }

  &.none {
    display: none;
  }

  .rt-arrow {
    display: inline-block;
    width: 0;
    height: 0;
    position: absolute;
    top: -5px;
    left: 21px;
    z-index: -1;

    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #525252;

    .rt-arrow-border {
      position: absolute;
      top: 0;
      left: -5px;

      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid rgba(255, 255, 255, 0.05);
    }

    .rt-arrow-inner {
      position: absolute;
      top: 1.414px;
      left: -5px;

      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #525252;
    }
  }
}

.rt-options-item {
  width: 52px;
  height: 36px;
  box-sizing: border-box;
  border-color: rgba(255, 255, 255, 0.05);
  border-style: solid;
  border-width: 1px 1px 1px 0;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: background 0.3s;

  font-size: 12px;
  color: #fff;
  overflow: hidden;

  &:nth-child(2) {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-left-width: 1px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.08);
  }
}
