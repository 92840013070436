.rt-button {
  height: 29px;
  padding: 0 16px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 4px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  transition: color, background 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  &.orange {
    background: #ff5e2f;

    &:hover {
      background: #ff6e43;
    }
  }

  &.disabled {
    background: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
  }

  .btn-left-icon {
    margin-right: 6px;
  }

  .btn-text {
    white-space: nowrap;
  }

  .btn-right-icon {
    transform: scale(0.6);
    position: relative;
    right: -3px;
  }
}
