.rt-slider {
  width: 257px;
  padding: 17px 10px 15px;
  border-radius: 3px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  box-sizing: border-box;
  background: #333;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.5);

  .rt-num-row {
    width: 237px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
  }

  .rt-scale-row {
    width: 211px;
    margin-top: 6px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;

    .rt-scale-item {
      display: inline-block;
      width: 1px;
      height: 5px;
      background-color: #5a5a5a;
      border-radius: 1px;
    }
  }

  .rt-scale-line {
    position: relative;
    top: -10px;
    width: 211px;
    height: 10px;
    border-bottom: 2px solid rgba(192, 192, 192, 0.3);
    cursor: pointer;

    .rt-point {
      position: absolute;
      top: 5px;
      left: -6px;
      width: 12px;
      height: 12px;

      border-radius: 20px;
      background-color: #ff5e2f;
      cursor: pointer;

      &:hover {
        .rt-point-bac {
          width: 21px;
          height: 21px;
          background-color: rgba(254, 98, 53, 0.15);
        }
      }

      &.transition {
        transition: left 0.2s;
      }

      .rt-point-bac {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 12px;
        height: 12px;
        border-radius: 11px;
        transition: background-color 0.3s, width 0.3s, height 0.3s;
        transform: translate(-50%, -50%);
      }
    }
  }
}
