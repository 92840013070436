@import '../common/common';

.rt-pop {
  z-index: 999;
  opacity: 0;

  &.absolute {
    position: absolute;
  }

  &.relative {
    position: relative;
  }

  &.fixed {
    position: fixed;
  }

  &.show {
    animation: show 0.3s;
    animation-fill-mode: forwards;
  }

  &.hide {
    animation: hide 0.3s;
    animation-fill-mode: forwards;
  }

  &.init,
  &.none {
    opacity: 0;
    display: none;
  }
}

.rt-pop-mask {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 998;
  width: 100vw;
  height: 100vh;
}
