@font-face {
  font-family: 'rticon'; /* Project id 2532854 */
  src: url('//at.alicdn.com/t/font_2532854_xuzz0zxbp2.woff2?t=1622692053906') format('woff2'),
    url('//at.alicdn.com/t/font_2532854_xuzz0zxbp2.woff?t=1622692053906') format('woff'),
    url('//at.alicdn.com/t/font_2532854_xuzz0zxbp2.ttf?t=1622692053906') format('truetype');
}

.rticon {
  font-family: 'rticon' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rticon-shengchengshipinicon:before {
  content: '\e632';
}

.rticon-Subtract:before {
  content: '\e631';
}

.rticon-pop-arrow:before {
  content: '\e630';
}

.rticon-fangdasuoxiao:before {
  content: '\e62f';
}

.rticon-tianjia:before {
  content: '\e62e';
}

.rticon-toast-chenggong:before {
  content: '\e62b';
}

.rticon-toast-cuowu:before {
  content: '\e62d';
}

.rticon-toast-tishi:before {
  content: '\e62a';
}

.rticon-insert:before {
  content: '\e62c';
}

.rticon-cover:before {
  content: '\e60e';
}

.rticon-arrow-right:before {
  content: '\e627';
}

.rticon-error-icon:before {
  content: '\e628';
}

.rticon-arrow-left:before {
  content: '\e629';
}

.rticon-arrow-down:before {
  content: '\e618';
}

.rticon-close:before {
  content: '\e61d';
}

.rticon-arrow-right-blod:before {
  content: '\e61e';
}

.rticon-arrow-left-blod:before {
  content: '\e61f';
}

.rticon-add:before {
  content: '\e620';
}

.rticon-full-screen:before {
  content: '\e621';
}

.rticon-circle-play:before {
  content: '\e622';
}

.rticon-check:before {
  content: '\e623';
}

.rticon-delete:before {
  content: '\e624';
}

.rticon-data:before {
  content: '\e626';
}

.rticon-error:before {
  content: '\e625';
}

.rticon-arrow-down-bold:before {
  content: '\e61c';
}

.rticon-import:before {
  content: '\e60f';
}

.rticon-speed:before {
  content: '\e610';
}

.rticon-setting:before {
  content: '\e611';
}

.rticon-switch-anchor:before {
  content: '\e612';
}

.rticon-yin:before {
  content: '\e613';
}

.rticon-pause:before {
  content: '\e614';
}

.rticon-stop:before {
  content: '\e615';
}

.rticon-play:before {
  content: '\e616';
}

.rticon-menu:before {
  content: '\e617';
}

.rticon-video-data:before {
  content: '\e619';
}

.rticon-video-management:before {
  content: '\e61a';
}

.rticon-video-production:before {
  content: '\e61b';
}
