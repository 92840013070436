$pageMaxWidth: 1600px;
$pageMinWidth: 1440px;
$pageMinHeight: 618px;

#root {
  height: 100%;
  overflow: auto;
  min-height: $pageMinHeight;
  position: relative;
  background: #fafbfd;
}

.page-wp {
  min-width: $pageMinWidth;
  max-width: $pageMaxWidth;
  margin: 0 auto;
}

.page-header-wp {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 500;
  background: linear-gradient(#0277dd, #1e88e5);

  .page-header {
    max-width: $pageMaxWidth;
    margin: 0 auto;
    padding: 0 15px;
    height: 60px;
    line-height: 60px;
    color: #fff;
    justify-content: space-between;

    .sys-name {
      font-size: 24px;
      font-weight: bold;
    }

    .right-Menu {
      color: #fff;

      .anticon {
        font-size: 20px !important;
        font-weight: bold;
      }
    }
  }

  .titlebar-wp {
    background: #fff;

    .titlebar {
      max-width: $pageMaxWidth;
      margin: 0 auto;

      .ant-menu-item .anticon,
      .ant-menu-submenu-title .anticon {
        font-size: 16px;
        margin-right: 6px;
      }
    }
  }
}

.page-content-wp {
  padding: 130px 20px 20px 20px;

  .ant-page-header-title-view-extra {
    top: 0;
  }

  .ant-page-header-heading-extra {
    top: 0;
  }
}

.page-content-wp > .ant-card {
  padding-bottom: 100px;

  .page-content-hearder {
    min-height: 32px;
  }
}

.page-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 500;
}
