.com-page-wp.login-page {
  width: 100%;
  min-width: 1440px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  background: rgba(250, 251, 253, 1);

  .main-bg {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -395px;
    margin-left: -625px;
    width: 720px;
    height: 790px;

    img {
      width: 100%;
    }
  }

  .main-content {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -200px;
    margin-left: 95px;
    z-index: 100;

    .sys-name {
      height: 40px;
      font-size: 22px;
      justify-content: space-between;
      color: #1e88e5;
      margin-bottom: 20px;
    }
    .login-image-code-btn {
      padding: 0;
      border: 0;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
